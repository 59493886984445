var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "90%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        top: "5vh",
        "custom-class": "form-config-dlg",
        title: _vm.$t("lang_form_configuration"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.dialogVisible
            ? _c(
                "el-form",
                {
                  ref: "formConfig",
                  attrs: {
                    model: _vm.formConfig,
                    rules: _vm.formRules,
                    "label-suffix": ": ",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticClass: "box-card-title" }, [
                        _vm._v(_vm._s(_vm.$t("lang_form_settings"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("lang_form_name"),
                                prop: "name",
                              },
                            },
                            [
                              _c("lang-input", {
                                staticClass: "input",
                                attrs: {
                                  placeholder: _vm.$t("lang_please_enter"),
                                },
                                model: {
                                  value: _vm.formConfig.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formConfig, "name", $$v)
                                  },
                                  expression: "formConfig.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预览背景色", prop: "name" } },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value:
                                    _vm.formConfig.container.properties
                                      .backgroundColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formConfig.container.properties,
                                      "backgroundColor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formConfig.container.properties.backgroundColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_label_width") } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                model: {
                                  value:
                                    _vm.formConfig.container.properties
                                      .labelWidth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formConfig.container.properties,
                                      "labelWidth",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formConfig.container.properties.labelWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_label_position") } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value:
                                      _vm.formConfig.container.properties
                                        .labelPosition,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formConfig.container.properties,
                                        "labelPosition",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formConfig.container.properties.labelPosition",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_align_left"),
                                      value: "left",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_center"),
                                      value: "center",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_align_right"),
                                      value: "right",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_show_border") } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.formConfig.container.properties
                                        .hasBorder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formConfig.container.properties,
                                        "hasBorder",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formConfig.container.properties.hasBorder",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lang_display"))
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v(" " + _vm._s(_vm.$t("lang_hide"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_cell_border") } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.formConfig.container.properties
                                        .cellBorder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formConfig.container.properties,
                                        "cellBorder",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formConfig.container.properties.cellBorder",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lang_display"))
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v(" " + _vm._s(_vm.$t("lang_hide"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_border_color") } },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value:
                                    _vm.formConfig.container.properties
                                      .borderColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formConfig.container.properties,
                                      "borderColor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formConfig.container.properties.borderColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8, offset: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: _vm.$t("lang_font_color") } },
                                [
                                  _c("el-color-picker", {
                                    model: {
                                      value:
                                        _vm.formConfig.container.properties
                                          .fontColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formConfig.container.properties,
                                          "fontColor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formConfig.container.properties.fontColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_margin") } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0 },
                                model: {
                                  value:
                                    _vm.formConfig.container.properties.margin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formConfig.container.properties,
                                      "margin",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formConfig.container.properties.margin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("lang_margin_color") } },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value:
                                    _vm.formConfig.container.properties
                                      .marginColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formConfig.container.properties,
                                      "marginColor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formConfig.container.properties.marginColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("lang_form_description") },
                            },
                            [
                              _c("lang-input", {
                                staticClass: "input",
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: _vm.$t("lang_please_enter"),
                                },
                                model: {
                                  value: _vm.formConfig.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formConfig, "description", $$v)
                                  },
                                  expression: "formConfig.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            {
              staticStyle: { "line-height": "32px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("el-col", { attrs: { span: 5, offset: 0 } }, [
                _c("span", { staticClass: "box-card-title" }, [
                  _vm._v(_vm._s(_vm.$t("lang_form_trigger_settings"))),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 3, offset: 16, align: "right" } },
                [
                  _c("el-button", {
                    attrs: {
                      title: _vm.$t("lang_import_trigger"),
                      circle: "",
                      icon: "el-icon-upload",
                      size: "small",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.importAction()
                      },
                    },
                  }),
                  _c("el-button", {
                    attrs: {
                      title: _vm.$t("lang_add_trigger"),
                      circle: "",
                      icon: "el-icon-plus",
                      size: "small",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addAction()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.formConfig.container.actions,
                "empty-text": _vm.$t("lang_no_data"),
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_trigger_name"),
                  align: "center",
                  prop: "title",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_trigger_Type"),
                  align: "center",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("lang_operation"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { round: "", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lang_edit")))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  type: "text",
                                  disabled: scope.row.global,
                                  round: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAction(scope.$index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lang_delete")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_add_trigger"),
            visible: _vm.actionDialogFlag,
            "close-on-click-modal": false,
            width: "64%",
            top: "10vh",
            "append-to-body": "",
          },
          on: { close: _vm.closeActionDialog },
        },
        [
          _c(
            "el-form",
            { staticClass: "trigger-form", attrs: { "label-width": "120px" } },
            [
              _c("el-divider", { staticClass: "cus-divider" }, [
                _vm._v(_vm._s(_vm.$t("lang_essential_information"))),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "trigger-form-item",
                          attrs: {
                            label: _vm.$t("lang_trigger_name"),
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "lang_please_enter_trigger_name"
                              ),
                            },
                            model: {
                              value: _vm.actionModel.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.actionModel, "title", $$v)
                              },
                              expression: "actionModel.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "trigger-form-item",
                          attrs: {
                            label: _vm.$t("lang_trigger_mode"),
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_the_trigger_method"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.actionModel.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.actionModel, "type", $$v)
                                },
                                expression: "actionModel.type",
                              },
                            },
                            _vm._l(_vm._actionTypeList, function (item) {
                              return _c("el-option", {
                                key: item.type,
                                attrs: { label: item.name, value: item.type },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "trigger-form-item",
                          attrs: {
                            label: _vm.$t("lang_trigger_sequence"),
                            prop: "type",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              size: "normal",
                              label: "",
                              min: 1,
                              max: 100,
                              step: 1,
                              controls: true,
                              "controls-position": "both",
                            },
                            model: {
                              value: _vm.actionModel.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.actionModel, "sort", $$v)
                              },
                              expression: "actionModel.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider", { staticClass: "cus-divider" }, [
                _vm._v(_vm._s(_vm.$t("lang_configuration_information"))),
              ]),
              _vm.actionModel.type
                ? _c("ele-trigger-config", {
                    ref: "triggerConfigRef",
                    attrs: {
                      triggerType: _vm.actionModel.type,
                      triggerGroups: _vm.actionModel.triggerGroups,
                    },
                    on: {
                      "update:triggerGroups": function ($event) {
                        return _vm.$set(
                          _vm.actionModel,
                          "triggerGroups",
                          $event
                        )
                      },
                      "update:trigger-groups": function ($event) {
                        return _vm.$set(
                          _vm.actionModel,
                          "triggerGroups",
                          $event
                        )
                      },
                    },
                  })
                : _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "text-align": "center",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("lang_please_select_the_trigger_method"))
                      ),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeActionDialog } }, [
                _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveActionConfig },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_import_trigger"),
            visible: _vm.actionImportDialogFlag,
            "close-on-click-modal": false,
            width: "64%",
            top: "10vh",
            "append-to-body": "",
          },
          on: { close: _vm.closeActionImportDialog },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "trigger-form",
              attrs: { "label-suffix": ": ", "label-width": "120px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "trigger-form-item",
                          attrs: {
                            label: _vm.$t("lang_trigger"),
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "id",
                                placeholder: "请选择触发器",
                                multiple: "",
                                clearable: "",
                                filterable: "",
                                "collapse-tags": "",
                              },
                              model: {
                                value: _vm.selectedWidgetActions,
                                callback: function ($$v) {
                                  _vm.selectedWidgetActions = $$v
                                },
                                expression: "selectedWidgetActions",
                              },
                            },
                            _vm._l(_vm.allWidgetActions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeActionImportDialog } }, [
                _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveActionImportData },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            {
              staticStyle: { "line-height": "32px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("el-col", { attrs: { span: 5, offset: 0 } }, [
                _c("span", { staticClass: "box-card-title" }, [
                  _vm._v(_vm._s(_vm.$t("lang_form_listener_config"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "label",
                {
                  staticStyle: {
                    width: "120px",
                    "padding-right": "12px",
                    "font-size": "16px",
                    "text-align": "right",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang_form_listeners")) + ": ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { multiple: "", "collapse-tags": "" },
                      on: { change: _vm.changeListeners },
                      model: {
                        value: _vm.currentFormListeners,
                        callback: function ($$v) {
                          _vm.currentFormListeners = $$v
                        },
                        expression: "currentFormListeners",
                      },
                    },
                    _vm._l(_vm._formListeners, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-button", {
                staticStyle: { "margin-left": "14px" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-setting",
                  size: "small",
                },
                on: { click: _vm.openListenerDialog },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_form_listener_params_config"),
            visible: _vm.listenerDialogFlag,
            "close-on-click-modal": false,
            width: "64%",
            top: "10vh",
            "append-to-body": "",
          },
          on: { close: _vm.closeListenerDialog },
        },
        [
          _c(
            "div",
            { staticClass: "listener-wrapper" },
            _vm._l(
              _vm.formConfig.container.formListeners,
              function (listenerItem) {
                return _c(
                  "div",
                  { key: listenerItem.value, staticClass: "listener-item" },
                  [
                    _c("label", { staticClass: "listener-item-title" }, [
                      _vm._v(_vm._s(listenerItem.name)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "listener-param-wrapper" },
                      _vm._l(
                        listenerItem.innerParams,
                        function (paramItem, index) {
                          return _c(
                            "div",
                            {
                              key: paramItem.text + index,
                              staticClass: "listener-param-item",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "listener-param-item-label" },
                                [_vm._v(_vm._s(paramItem.text))]
                              ),
                              _c(
                                "div",
                                { staticClass: "listener-param-item-value" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: paramItem.paramOptionName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            paramItem,
                                            "paramOptionName",
                                            $$v
                                          )
                                        },
                                        expression: "paramItem.paramOptionName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.formConfig.container.paramsOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.name,
                                          attrs: {
                                            label: item.text,
                                            value: item.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              }
            ),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeListenerDialog } }, [
                _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveListenerConfig },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            {
              staticStyle: { "line-height": "32px" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("el-col", { attrs: { span: 5, offset: 0 } }, [
                _c("span", { staticClass: "box-card-title" }, [
                  _vm._v(_vm._s(_vm.$t("lang_form_parameter_setting"))),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 3, offset: 16, align: "right" } },
                [
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      circle: "",
                      size: "small",
                      icon: "el-icon-plus",
                    },
                    on: { click: _vm.addFlowParam },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", height: "36px" },
                      attrs: {
                        type: "text",
                        disabled: !_vm.formId,
                        title: _vm.$t("lang_please_select_a_form_first"),
                      },
                      on: { click: _vm.showFormItemImportDialog },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lang_import_form_items")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "empty-text": _vm.$t("lang_no_data"),
                data: _vm.tableDataFilter,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: _vm.$t("lang_key"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            disabled: scope.row.type.type == "DEFINITION",
                            placeholder: _vm.$t("lang_please_enter"),
                          },
                          model: {
                            value: scope.row.name,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "name", $$v)
                            },
                            expression: "scope.row.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { "min-width": 100, label: _vm.$t("lang_name") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type.type == "DEFINITION"
                          ? _c("el-input", {
                              attrs: {
                                disabled: true,
                                placeholder: _vm.$t("lang_please_enter"),
                              },
                              model: {
                                value: scope.row.text,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "text", $$v)
                                },
                                expression: "scope.row.text",
                              },
                            })
                          : _c("lang-input", {
                              attrs: {
                                disabled: scope.row.type.type == "DEFINITION",
                                placeholder: _vm.$t("lang_please_enter"),
                              },
                              model: {
                                value: scope.row.text,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "text", $$v)
                                },
                                expression: "scope.row.text",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_type"),
                  align: "center",
                  "class-name": "params-item-type",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: scope.row.type.type == "DEFINITION",
                              "value-key": "value",
                              placeholder: _vm.$t("lang_please_select"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeFlowParamType(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.type,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "type", $$v)
                              },
                              expression: "scope.row.type",
                            },
                          },
                          _vm._l(_vm.paramsTypes, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: _vm.$t(item.text), value: item },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.changeFormItemDataType(scope.row)
                                },
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("lang_djjxglxxdsjsz"),
                              placement: "right",
                            },
                          },
                          [
                            scope.row.type.value ==
                              _vm.WidgetDataTypeEnum.COLLECTOR ||
                            scope.row.type.value == _vm.WidgetDataTypeEnum.TREE
                              ? _c("i", {
                                  staticClass: "el-icon-setting",
                                  staticStyle: {
                                    color: "#409eff",
                                    "margin-left": "10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setDataSurceForFlowParam(
                                        scope.row
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_parameter_defaults"),
                  align: "center",
                  width: "300px",
                  "class-name": "params-item-value",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isCollectorOrTree(scope.row)
                          ? [
                              scope.row.type.reality == "custom_options"
                                ? _c("treeselect", {
                                    ref: "defaultValue_" + scope.$index,
                                    staticClass: "cus-treeselect",
                                    attrs: {
                                      value: _vm.cc(scope.row.defaultValue),
                                      multiple: "",
                                      limit: 1,
                                      flat: "",
                                      appendToBody: "",
                                      zIndex: "999999",
                                      "value-consists-of": "ALL",
                                      options: scope.row.type.valueItems,
                                      normalizer: _vm.normalizerHandler,
                                      noOptionsText: _vm.$t("lang_no_data"),
                                      noResultsText: _vm.$t(
                                        "lang_no_search_results"
                                      ),
                                      placeholder: _vm.$t(
                                        "lang_set_default_values"
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        scope.row.defaultValue =
                                          $event.target.value
                                      },
                                    },
                                  })
                                : _c("treeselect", {
                                    ref: "defaultValue_" + scope.$index,
                                    staticClass: "cus-treeselect",
                                    attrs: {
                                      multiple: "",
                                      limit: 1,
                                      flat: "",
                                      appendToBody: "",
                                      zIndex: "999999",
                                      "value-consists-of": "ALL",
                                      defaultOptions: scope.row.type.valueItems,
                                      async: true,
                                      "load-options": function (val) {
                                        return _vm.getDataSourceForParamItemUseThrottle(
                                          val,
                                          scope.row
                                        )
                                      },
                                      normalizer: _vm.normalizerHandler,
                                      noOptionsText: _vm.$t("lang_no_data"),
                                      noResultsText: _vm.$t(
                                        "lang_no_search_results"
                                      ),
                                      placeholder: _vm.$t("lang_please_select"),
                                    },
                                    model: {
                                      value: scope.row.defaultValue,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "defaultValue", $$v)
                                      },
                                      expression: "scope.row.defaultValue",
                                    },
                                  }),
                            ]
                          : scope.row.type && scope.row.type.value == "NUMBER"
                          ? _c("el-input-number", {
                              attrs: {
                                size: "small",
                                placeholder: _vm.$t(
                                  "lang_autu_end_condition_value"
                                ),
                              },
                              model: {
                                value: scope.row.defaultValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "defaultValue",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "scope.row.defaultValue",
                              },
                            })
                          : scope.row.type && scope.row.type.value == "DATE"
                          ? _c("el-date-picker", {
                              attrs: {
                                size: "small",
                                clearable: true,
                                placeholder: _vm.$t("lang_please_select"),
                                format: "yyyy-MM-dd",
                                type: "date",
                                "value-format": "timestamp",
                              },
                              model: {
                                value: scope.row.defaultValue,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "defaultValue", $$v)
                                },
                                expression: "scope.row.defaultValue",
                              },
                            })
                          : scope.row.type && scope.row.type.value == "FILE"
                          ? _c("file-input", {
                              attrs: {
                                size: "small",
                                placeholder: _vm.$t(
                                  "lang_autu_end_condition_value"
                                ),
                              },
                              model: {
                                value: scope.row.defaultValue,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "defaultValue", $$v)
                                },
                                expression: "scope.row.defaultValue",
                              },
                            })
                          : _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: _vm.$t("lang_set_default_values"),
                              },
                              model: {
                                value: scope.row.defaultValue,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "defaultValue", $$v)
                                },
                                expression: "scope.row.defaultValue",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("lang_operation"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.type.value != "DEFINITION",
                                  type: "text",
                                  round: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showInnerParamsDialog(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("lang_Internal_parameters"))
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  type: "text",
                                  disabled: scope.row.global,
                                  round: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteParamsConfigItem(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_Internal_parameters"),
            "append-to-body": "",
            visible: _vm.innerParamDialogVisiable,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerParamDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "min-height": "300px", width: "60%" } },
            _vm._l(_vm.currentParamOption.innerParams, function (item) {
              return _c("variable-selector", {
                key: item.key,
                attrs: {
                  selectorKey: item.key,
                  variableTitle: item.text,
                  paramsOptions: _vm.innerParamsOptions,
                },
                model: {
                  value: item.paramOptionName,
                  callback: function ($$v) {
                    _vm.$set(item, "paramOptionName", $$v)
                  },
                  expression: "item.paramOptionName",
                },
              })
            }),
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerParamDialogVisiable = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_setting"),
            "append-to-body": "",
            visible: _vm.setValueItemsDialogFlag,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            width: "60%",
            "custom-class": "datasource-config-dlg",
          },
          on: {
            "update:visible": function ($event) {
              _vm.setValueItemsDialogFlag = $event
            },
          },
        },
        [
          _c("el-divider", { staticClass: "cus-divider" }, [
            _vm._v(_vm._s(_vm.$t("lang_source_type"))),
          ]),
          _c(
            "div",
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lang_please_select")) + "：")]),
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-left": "6px" },
                  on: { change: _vm.changeParamTypeReality },
                  model: {
                    value: _vm.currentParamOption.type.reality,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentParamOption.type, "reality", $$v)
                    },
                    expression: "currentParamOption.type.reality",
                  },
                },
                _vm._l(_vm.realityList, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.code, attrs: { label: item.code } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.currentParamOption.type.reality == "custom_options"
            ? [
                _c("el-divider", { staticClass: "cus-divider" }, [
                  _vm._v(_vm._s(_vm.$t("lang_source_type"))),
                ]),
                _vm.currentParamOption.type.value ==
                _vm.widgetDataTypeEnum.COLLECTOR
                  ? [
                      _c("div", { staticClass: "node-action-container" }, [
                        _c("div", { staticClass: "node-action-header" }, [
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("lang_name")))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("lang_number_"))),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("lang_state")))])]
                          ),
                          _c(
                            "div",
                            { staticClass: "node-action-header-item" },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-plus",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: _vm.addDataSourceForCollectorParam,
                                },
                              }),
                              _c("el-button", {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-brush",
                                  size: "mini",
                                  circle: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "node-action-content" },
                          _vm._l(_vm.valueItems, function (btnItem, btnIndex) {
                            return _c(
                              "div",
                              { key: btnIndex, staticClass: "node-action-row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder:
                                          _vm.$t("lang_please_enter"),
                                        size: "small",
                                      },
                                      model: {
                                        value: btnItem.text,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "text", $$v)
                                        },
                                        expression: "btnItem.text",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder:
                                          _vm.$t("lang_please_enter"),
                                      },
                                      model: {
                                        value: btnItem.code,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "code", $$v)
                                        },
                                        expression: "btnItem.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-switch", {
                                      attrs: { size: "small" },
                                      model: {
                                        value: btnItem.disabled,
                                        callback: function ($$v) {
                                          _vm.$set(btnItem, "disabled", $$v)
                                        },
                                        expression: "btnItem.disabled",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "node-action-item" },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-plus",
                                        size: "small",
                                        circle: "",
                                      },
                                      on: {
                                        click:
                                          _vm.addDataSourceForCollectorParam,
                                      },
                                    }),
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        size: "small",
                                        circle: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : _vm.currentParamOption.type.value ==
                    _vm.widgetDataTypeEnum.TREE
                  ? [
                      _c(
                        "div",
                        { staticClass: "node-action-container" },
                        [
                          _c("div", { staticClass: "node-action-header" }, [
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_name"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_number_"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("lang_state"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "node-action-header-item" },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-plus",
                                    size: "mini",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addNodeForTreeParam()
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-brush",
                                    size: "mini",
                                    circle: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.valueItems.length
                            ? _c("el-tree", {
                                ref: "appTreeRef",
                                staticClass: "node-action-tree",
                                attrs: {
                                  data: _vm.valueItems,
                                  props: {
                                    label: "text",
                                    children: "children",
                                  },
                                  "default-expand-all": "",
                                  "expand-on-click-node": false,
                                  "highlight-current": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "node-action-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "lang_please_enter"
                                                        ),
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: data.text,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "text",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "data.text",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "lang_please_enter"
                                                        ),
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value: data.code,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "code",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "data.code",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-switch", {
                                                    attrs: { size: "small" },
                                                    model: {
                                                      value: data.disabled,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          data,
                                                          "disabled",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.disabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "node-action-item",
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-plus",
                                                      size: "mini",
                                                      circle: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addNodeForTreeParam()
                                                      },
                                                    },
                                                  }),
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-delete",
                                                      size: "mini",
                                                      circle: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeNodeForTreeParam(
                                                          node,
                                                          data
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4190318741
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setValueItemsDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmValueItems },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveFormConfig()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
      _vm.formId
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("lang_import_form_items"),
                "append-to-body": "",
                visible: _vm.importFormDialogVisible,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.importFormDialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { height: "300px" },
                  attrs: { "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_form_item") } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: true,
                          appendToBody: "",
                          zIndex: "9999",
                          "disable-branch-nodes": true,
                          options: _vm.formWidgetTree,
                          valueFormat: "object",
                          placeholder: _vm.$t("lang_please_select"),
                        },
                        on: { open: _vm.isSelectedForm },
                        model: {
                          value: _vm.selectedFormItemValue,
                          callback: function ($$v) {
                            _vm.selectedFormItemValue = $$v
                          },
                          expression: "selectedFormItemValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.importFormDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveFormItemConfig },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }