

















































































































































































































































































































































































































































































































































































































































import { Component, InjectReactive, Prop, Vue, Watch } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import VariableSelector from '@/components/flow-design/flow-config/node-variable-selector.vue';
import { FormData, FormModel } from '@/models/form/FormModel';
import { saveFormConfigsData } from '@/api/form-design/FormApi';
import { ParamOptionData, ParamOptionModel, ValueItemModel, ParamTypeModel } from '@/models/flow/FlowConfigModel';
import { WidgetDataTypeDict, WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import StoreIndex from '@/store/StoreIndex';
import { getFormConfig } from '../utils';
import { buildFormWidgetTree } from '@/components/form-design/utils';
import { WidgetActionData, WidgetActionModel } from '@/models/form/WidgetActionModel';
import { getFlowParamDataSource, getRealities, saveFlow } from '@/api/flow-design/FlowApi';
import EleTriggerConfig from './ele-trigger-config.vue';
import FileInput from '@/components/file-input/file-input.vue';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { throttle } from 'lodash';

@Component({
  name: 'FormConfig',
  components: { VariableSelector, EleTriggerConfig, FileInput }
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  // 应用id
  @Prop({ default: '' }) applicationId!: string;
  @Prop({ default: false }) connectForm!: boolean;

  formConfig: FormModel = SystemUtil.cloneDeep(FormData);
  innerParamDialogVisiable: boolean = false;
  setValueItemsDialogFlag: boolean = false;
  currentParamOption: ParamOptionModel = ParamOptionData;
  valueItems: ValueItemModel[] = [];
  paramsTypes: Record<string, string>[] = WidgetDataTypeDict;
  WidgetDataTypeEnum: any = WidgetDataTypeEnum;
  formListenerList: any[] = [];
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: '请填写表单名称',
      trigger: 'blur'
    }
  };

  formWidgetTree: Record<string, any>[] = []; //导入表单项 下拉框数据
  selectedFormItemValue: Record<string, any>[] = [];
  importFormDialogVisible: boolean = false; //导入表单项

  actionDialogFlag: boolean = false; // 触发器弹出框显示与否
  actionModel: WidgetActionModel = SystemUtil.cloneDeep(WidgetActionData); // 触发器对象
  actionList: WidgetActionModel[] = [];

  listenerDialogFlag: boolean = false; // 监听器弹出框显示与否
  currentFormListeners: any[] = [];

  actionImportDialogFlag: boolean = false; // 触发器导入弹出框显示与否
  selectedWidgetActions: any[] = []; // 选择引入的表单触发器
  allWidgetActions: any[] = []; // 表单项已配置的触发器
  widgetDataTypeEnum: any = WidgetDataTypeEnum;
  realityList: any[] = [];
  connectFormAndFlowId: string = '';
  dialogVisibleConst: boolean = true;

  @InjectReactive()
  formId: any;

  get innerParamsOptions() {
    return this.formConfig.container.paramsOptions.filter(item => {
      return item.name != this.currentParamOption.name;
    });
  }

  get _actionTypeList() {
    let result: any[] = [];
    let actionStruct = this.$store.getters.actionStruct;
    if (actionStruct) {
      let actions: any = Object.values(actionStruct);
      if (actions) {
        result = actions.filter(item => item.scope == 'FORM');
      }
    }
    return result;
  }

  get _formListeners() {
    return this.$store.getters.formListeners;
  }

  created() {
    this.initData();
  }

  /**
   * 初始化数据
   */
  initData() {
    if (this.formId) {
      this.formConfig = getFormConfig(this.formId) || SystemUtil.cloneDeep(FormData);
      if (!this.formConfig.container.paramsOptions) {
        this.formConfig.container.paramsOptions = [];
      }
      if (!this.formConfig.container.actions) {
        this.formConfig.container.actions = [];
      }
      if (this.formConfig.container.formListeners) {
        // 去除空值
        let arr = this.formConfig.container.formListeners.filter(item => item);
        this.formConfig.container.formListeners = arr;

        this.currentFormListeners = arr.map(item => item.value);
      } else {
        this.currentFormListeners = [];
      }
    } else {
      this.formConfig = SystemUtil.cloneDeep(FormData);
      this.currentFormListeners = [];
    }

    this.formWidgetTree = buildFormWidgetTree(this.formConfig.container.components); //导入表单项 下拉框数据
    this.getRealityList();
    this.buildDataSourceForParams();
  }

  /*
   *@description: 保存表单
   *@author: gongcaixia
   *@date: 2021-05-18 10:34:09
   */
  saveFormConfig() {
    if (!this.formConfig.name) {
      return;
    }
    if (!this.formConfig.version) {
      this.formConfig.version = null;
    }
    let formConfig = getFormConfig(this.formId);
    formConfig.name = this.formConfig.name;
    formConfig.description = this.formConfig.description;
    formConfig.version = this.formConfig.version;
    formConfig.container.actions = this.formConfig.container.actions;
    formConfig.container.properties = this.formConfig.container.properties;
    formConfig.container.paramsOptions = this.formConfig.container.paramsOptions;
    formConfig.applicationId = this.applicationId;

    saveFormConfigsData(formConfig).then((res: any) => {
      if (res.code == '1') {
        if (this.connectForm) {
          this.connectFormAndFlowId = res.data.id;
          this.connectFormAndFlow();
        }
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$emit('success');
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
      this.$emit('update:visible', false);
    });
  }

  // 当组件在流程图中挂载时 新建表单之后-关联流程  关联流程参数来源于组件 flow-graoh -> session中 connectData
  connectFormAndFlow() {
    let data: any = JSON.parse(sessionStorage.getItem('connectData') as any);
    if (data) {
      if (!this.connectFormAndFlowId) {
        console.error('没有FormID');
        return;
      }
      data.customBpmModel.config.formId = this.connectFormAndFlowId;
      saveFlow(data).then((res: Record<string, any>) => {
        if (res.code == '1') {
          sessionStorage.setItem('loadForm', '1');
          this.$message.success(res.message);
          window.location.reload();
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
  /**
   * 获取realitiy
   */
  getRealityList() {
    getRealities({}).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.realityList = res.data;
      } else {
        this.realityList = [];
      }
    });
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 点击类型下拉的每项时触发，当类型为自定义时 ，用户能手输内容
   *@author: gongcaixia
   *@date: 2021-04-25 17:03:21
   *@params1: 当前行信息
   */
  changeFormItemDataType(row) {
    if (row.type.value == 'DEFINITION') {
      this.$prompt('请输入自定义内容', this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        inputValue: row.type.text
      })
        .then((value: any) => {
          row.type.text = value.value;
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    }
  }

  /*
   *@description:流程设置弹框内, 流程参数设置的添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-25 13:33:03
   */
  addFlowParam() {
    if (this.validateFlowParam()) {
      if (!this.formConfig.container.paramsOptions) this.formConfig.container.paramsOptions = [];
      this.formConfig.container.paramsOptions.push({
        name: '',
        type: {
          text: '',
          value: ''
        },
        defaultValue: [],
        text: '',
        global: false
      });
    }
  }

  isCollectorOrTree(formItem) {
    return (
      formItem.type && (formItem.type.value == this.widgetDataTypeEnum.TREE || formItem.type.value == this.widgetDataTypeEnum.COLLECTOR)
    );
  }
  /**
   * 为每个类型为集合或者数组的流程参数构建自己的数据源选项
   */
  buildDataSourceForParams() {
    if (this.formConfig.container.paramsOptions) {
      let paramsOptions = this.formConfig.container.paramsOptions;
      paramsOptions.forEach(paramItem => {
        if (!paramItem.type.valueItems) {
          // paramItem.type.valueItems = [];
          this.$set(paramItem.type, 'valueItems', []);
        }
        if (paramItem.type.reality == 'custom_options') {
        } else {
          if (this.isCollectorOrTree(paramItem)) {
            if (!paramItem.defaultValue) {
              paramItem.defaultValue = [];
            }
            let params = {
              formId: this.formId,
              paramsOption: paramItem,
              codes: paramItem.defaultValue
            };
            getFlowParamDataSource(params)
              .then((res: any) => {
                let result = [];
                if (res.code == '1' && res.data && res.data.length) {
                  result = res.data;
                }
                paramItem.type.valueItems = result;
              })
              .catch(() => {
                paramItem.type.valueItems = [];
              });
          }
        }
      });
    } else {
      // this.setValueItemsDialogFlag = true;
    }
  }
  normalizerHandler(node) {
    return {
      id: node.code,
      label: node.text,
      children: node.children
    };
  }
  /**
   * 改变流程参数的reality
   */
  changeParamTypeReality(realityCode) {
    let reality = this.realityList.find(item => {
      return item.code == realityCode;
    });
    this.currentParamOption.type.text = reality.name;
  }
  /**
   * 获取值选项
   */

  getDataSourceForParamItemUseThrottle = throttle(this.getDataSourceForParamItem, 1000, { leading: false, trailing: true });

  getDataSourceForParamItem(data, row) {
    if (data.action === ASYNC_SEARCH) {
      if (data.searchQuery) {
        let params = {
          formId: this.formId,
          paramsOption: row,
          text: data.searchQuery
        };
        getFlowParamDataSource(params)
          .then((res: any) => {
            let result = [];
            if (res.code == '1' && res.data && res.data.length) {
              result = res.data;
            }
            // row.type.valueItems = result;

            data.callback(null, result);
          })
          .catch(() => {
            data.callback(null, []);
          });
      } else {
        data.callback(null, []);
      }
    }
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的添加按钮时的 校验
   *@author: gongcaixia
   *@date: 2021-04-27 09:12:12
   */
  validateFlowParam() {
    let flag = true;
    if (this.formConfig.container.paramsOptions && this.formConfig.container.paramsOptions.length) {
      let item = this.formConfig.container.paramsOptions[this.formConfig.container.paramsOptions.length - 1];
      if (!item.name || !item.type || !item.text) {
        this.$message.warning(this.$t('lang_perfect_param_config') as string);
        flag = false;
      }
    } else {
      flag = true;
    }
    return flag;
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时 默认值置空
   *@author: gongcaixia
   *@date: 2021-04-25 14:04:12
   *@params1: 当前行信息
   */
  changeFlowParamType(row) {
    row.defaultValue = [];
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时 当类型为 集合或树形时 需要设置 valueItems
   *@author: gongcaixia
   *@date: 2021-04-26 14:10:03
   *@params1: 当前行信息
   */
  setDataSurceForFlowParam(row) {
    this.currentParamOption = row;

    this.setValueItemsDialogFlag = true;
    this.valueItems =
      this.currentParamOption.type && this.currentParamOption.type.valueItems ? this.currentParamOption.type.valueItems : [];
  }
  /*
   *@description:  流程设置弹框内, 流程参数设置的 切换参数类型时， 当类型为集合时，要设置 valueItems的弹框的添加按钮事件
   *@author: gongcaixia
   *@date: 2021-04-26 14:51:30
   */
  addDataSourceForCollectorParam() {
    this.valueItems.push({
      text: '',
      code: '',
      disabled: false
    });
  }

  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为集合时，要设置 valueItems的弹框的表格 删除事件
   *@author: gongcaixia
   *@date: 2021-04-26 14:51:30
   *@variable1: 行索引
   */
  deleteDataSourceForCollectorParam(index) {
    this.valueItems.splice(index, 1);
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 添加事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  addNodeForTreeParam() {
    let code = SystemUtil.uuid();
    this.$prompt('请输入节点名称', {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string
    }).then((value: any) => {
      this.valueItems.push({
        text: value.value,
        code: code,
        disabled: false,
        children: []
      });
    });
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 添加事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  appendNodeForTreeParam(data) {
    this.$prompt('请输入节点名称', {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string
    }).then((value: any) => {
      const newChild = {
        code: SystemUtil.uuid(),
        text: value.value,
        children: [],
        disabled: false
      };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(newChild);
    });
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 删除事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  removeNodeForTreeParam(node, data) {
    const parent = node.parent;
    const children = parent.data.children || parent.data;
    const index = children.findIndex(d => d.id === data.id);
    children.splice(index, 1);
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 禁用事件
   *@author: gongcaixia
   *@date: 2021-04-26 15:07:48
   */
  setTreeNodeDisabled(data) {
    this.$set(data, 'disabled', !data.disabled);
    let message = data.disabled ? '该节点设置禁用成功！' : '该节点解禁成功';
    this.$message({
      type: 'success',
      message: message
    });
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的 切换参数类型时，当类型为树形时，要设置 valueItems的弹框的 确认事件
   *@author: gongcaixia
   *@date: 2021-04-26 17:19:40
   */
  confirmValueItems() {
    this.currentParamOption.type.valueItems = this.valueItems;
    this.setValueItemsDialogFlag = false;
  }
  /*
   *@description: 流程设置弹框内, 流程参数设置的表格删除按钮事件
   *@author: gongcaixia
   *@date: 2021-04-25 13:35:10
   *@params1: 点击删除的行索引
   */
  deleteParamsConfigItem(row) {
    this.formConfig.container.paramsOptions.map((item, i) => {
      if (item.name == row.name) {
        this.formConfig.container.paramsOptions.splice(i, 1);
        return;
      }
    });
  }

  /**
   * 点击内部参数设置按钮时
   */
  showInnerParamsDialog(currentParamOption) {
    this.currentParamOption = currentParamOption;
    this.innerParamDialogVisiable = true;
  }

  // 关闭弹窗
  handleCloseDialog() {
    // this.formConfig = FormData;
    this.$emit('update:visible', false);
  }

  // 添加触发器
  addAction() {
    if (this.formConfig.container.components && this.formConfig.container.components.length) {
      this.actionModel = SystemUtil.cloneDeep(WidgetActionData);
      this.actionDialogFlag = true;
    } else {
      this.$message.warning(this.$t('lang_please_add_formitem') as string);
    }
  }

  // 编辑触发器
  editAction(action: WidgetActionModel) {
    this.actionModel = SystemUtil.cloneDeep(action);
    this.actionDialogFlag = true;
  }

  // 删除触发器
  removeAction(index) {
    this.formConfig.container.actions.splice(index, 1);
    this.actionModel = SystemUtil.cloneDeep(WidgetActionData);
  }

  /**
   * 保存触发器配置
   */
  saveActionConfig() {
    (this.$refs.triggerConfigRef as any).validateAllGroup().then(v => {
      if (v) {
        if (this.actionModel.type) {
          let curSelectActionType = this._actionTypeList.find(item => item.type == this.actionModel.type);
          let actionConfig: WidgetActionModel = {
            type: curSelectActionType.type,
            name: curSelectActionType.name,
            title: this.actionModel.title,
            groupType: curSelectActionType.groupType,
            sort: this.actionModel.sort,
            triggerGroups: (this.$refs.triggerConfigRef as any).getTriggerGroupsResult()
          };
          if (!this.actionModel.id) {
            actionConfig.id = SystemUtil.uuid();
            this.actionModel.id = actionConfig.id;
          }
          if (!this.formConfig.container.actions || this.formConfig.container.actions == 0) {
            this.formConfig.container.actions = [actionConfig];
          } else {
            let curActionIndex = this.formConfig.container.actions.findIndex(item => actionConfig.type == item.type);
            this.formConfig.container.actions.splice(curActionIndex, 1, actionConfig);
          }
          this.actionDialogFlag = false;
        }
      } else {
        this.$message.warning(this.$t('lang_perfect_param_config') as string);
      }
    });
  }
  /**
   * 关闭触发器
   */
  closeActionDialog() {
    this.actionDialogFlag = false;
  }

  /**
   * 弹出配置监听器弹窗
   */
  openListenerDialog() {
    this.listenerDialogFlag = true;
  }

  /**
   * 改变监听器时
   */
  changeListeners(values) {
    let formListeners = [];
    this._formListeners.forEach(item => {
      if (values.includes(item.value)) {
        formListeners.push(SystemUtil.cloneDeep(item));
      }
    });
    this.formConfig.container.formListeners = formListeners;
  }

  /**
   * 关闭配置监听器弹窗
   */
  closeListenerDialog() {
    this.listenerDialogFlag = false;
  }

  /**
   * 保存触发器配置
   */
  saveListenerConfig() {
    this.listenerDialogFlag = false;
  }

  // 从所有组件的触发器中导入
  importAction() {
    let components = this.formConfig.container.components;
    if (components && components.length) {
      this.allWidgetActions = this.getAllWidgetActions(components);
      this.selectedWidgetActions = this.buildEchoActions();
      this.actionImportDialogFlag = true;
    } else {
      this.$message.warning(this.$t('lang_please_add_formitem') as string);
    }
  }
  // 循环遍历表单组件，并构建表单项的数据源
  getAllWidgetActions(components) {
    let result: any = [];
    components.forEach(compItem => {
      if (compItem.compType == 'card') {
        if (compItem.properties.components) {
          result = result.concat(this.getAllWidgetActions(compItem.properties.components));
        }
      } else if (compItem.compType == 'row') {
        compItem.properties.gridColumns.forEach(gridCol => {
          gridCol.components.forEach(gridCell => {
            if (gridCell.actions && gridCell.actions.length) {
              result = result.concat(gridCell.actions);
            }
          });
        });
      } else if (compItem.compType == 'tab') {
        compItem.properties.children.forEach(item => {
          item.components.forEach(tabCell => {
            if (tabCell.actions && tabCell.actions.length) {
              result = result.concat(tabCell.actions);
            }
          });
        });
      } else if (compItem.compType == 'table') {
        compItem.properties.components.forEach(tableItem => {
          if (tableItem.actions && tableItem.actions.length) {
            result = result.concat(tableItem.actions);
          }
        });
      } else {
        if (compItem.actions && compItem.actions.length) {
          result = result.concat(compItem.actions);
        }
      }
    });
    return result;
  }

  /**
   * 回显从表单引入的触发器
   */
  buildEchoActions() {
    let actions = this.formConfig.container.actions;
    if (actions && actions.length) {
      let widgetActionsMap = {};
      this.allWidgetActions.forEach(item => {
        widgetActionsMap[item.id] = true;
      });
      return actions.filter(item => widgetActionsMap[item.id]);
    }
    return [];
  }

  /**
   * 关闭导入触发器弹出框
   */
  closeActionImportDialog() {
    this.actionImportDialogFlag = false;
  }

  /**
   * 保存导入数据
   */
  saveActionImportData() {
    if (this.selectedWidgetActions && this.selectedWidgetActions.length) {
      let actionsClone = SystemUtil.cloneDeep(this.formConfig.container.actions);
      let selectedActions = SystemUtil.cloneDeep(this.selectedWidgetActions);
      let mergeTriggerGroups: any[] = [];
      selectedActions.forEach(item => {
        item.triggerGroups.forEach(groupItem => {
          groupItem.id = SystemUtil.uuid();
          mergeTriggerGroups.push(groupItem);
        });
      });
      if (actionsClone && actionsClone.length) {
        actionsClone[0].triggerGroups = actionsClone[0].triggerGroups.concat(mergeTriggerGroups);
      } else {
        actionsClone = [
          {
            id: SystemUtil.uuid(),
            groupType: 'html',
            name: '页面加载',
            scope: 'COMPONENTS',
            sort: 1,
            title: '页面加载触发器',
            triggerGroups: mergeTriggerGroups,
            type: 'LOAD'
          }
        ];
      }
      this.formConfig.container.actions = actionsClone;
    }
    this.closeActionImportDialog();
  }

  /**
   * 过滤-表单参数设置-表格数据
   */
  get tableDataFilter() {
    return this.formConfig.container.paramsOptions.filter((item: any) => !item.hidden);
  }

  /**
   * description  // 导入表单项
   * @param {}
   * @returns {}
   */
  showFormItemImportDialog() {
    if (this.validateFlowParam()) {
      this.importFormDialogVisible = true;
    }
  }

  /**
   * description  导入表单项弹框 确定时
   * @param {}

   * @returns {}
   */
  saveFormItemConfig() {
    this.selectedFormItemValue.map(item => {
      let obj = this.paramsTypes.find(el => el.value == item.dataType);
      let _type: ParamTypeModel = {
        text: obj ? obj.text : '',
        value: item.dataType,
        reality: item.reality,
        truthType: item.truthType,
        valueItems: item.valueItems
      };
      this.formConfig.container.paramsOptions.push({
        name: item.compCode,
        type: _type,
        innerParams: [],
        defaultValue: [],
        hidden: false,
        formImport: true,
        text: item.label,
        global: false
      });
    });
    this.importFormDialogVisible = false;
  }

  /**
   * description  导入表单项弹框的 打开选择表单项下拉框时
   * @param {}
   * @returns {}
   */
  isSelectedForm() {
    if (!this.formId) {
      this.$message.warning(this.$t('lang_please_select_a_form_first') as any);
      return;
    }
  }
  //treeselect组件 v-model绑定值问题
  cc(data) {
    if (Array.isArray(data)) {
      return data;
    } else {
      return [];
    }
  }

  get dialogVisible() {
    return this.visible;
  }
  set dialogVisible(newValue) {
    this.$emit('update:visible', false);
  }

  @Watch('formId')
  onFormIdWatcher(value: FormModel) {
    this.initData();
  }
}
